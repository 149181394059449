import React from 'react';
import { NavBar, Image, Rate, Toast } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import { LoadingView, GameIcon, Adsbygoogle, PageBottom } from '@component';
import { IMAGE } from "@/assets";
import { GlobalCtg } from "@config/config";
import { doSearchGame, doQueryList } from '@util/HttpUtil';
import { getErrorCodeMsg, saveHotListToLocal, getLocalData, getHotListForDetails, saveLastGameToLocal, getTrueUrl, initAdScript } from '@util/GameUtil';
import './styles.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // 是否加载中
            gameData: null, // 游戏数据
            hotList: null, // 热门列表
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {
        // 获取游戏id
        const { gid } = this.props.match.params;
        // 记录当前游戏id
        this.curGameId = gid;
        // 展示loading
        this.setState({ isLoading: true });
        // 请求数据
        doSearchGame(gid, (_resultObj) => {
            // 关闭loading
            this.setState({ isLoading: false });
            // 判断状态
            if (_resultObj.s === 0) {
                // 是否有游戏数据
                if (_resultObj.data) {
                    // 设置游戏数据刷新界面
                    this.setState({ gameData: _resultObj.data }, () => {
                        // 展示横幅广告4
                        if (window.showNor4Ad) {
                            window.showNor4Ad();
                        }
                    });
                }
            } else {
                Toast.show({ content: getErrorCodeMsg(_resultObj.s) });
            }
        });
        // 获取热门列表
        var hList = getLocalData('hfhotlist');
        // 判断是否存在
        if (!hList || hList.length <= 0) {
            // 执行查询
            doQueryList(GlobalCtg.listType.hots, 1, (_resultObj) => {
                // 判断是否挂载
                if (!this.mounted) {
                    return
                }
                // 判断状态
                if (_resultObj.s === 0) {
                    // 判断是否有数据
                    if (_resultObj.data && _resultObj.data.list && _resultObj.data.list.length > 0) {
                        // 当前数据
                        let list = _resultObj.data.list;
                        // 保存数据到本地
                        saveHotListToLocal(list);
                        // 展示热门列表数据
                        this.setState({ hotList: getHotListForDetails(gid) });
                    }
                }
            });
        } else {
            // 展示热门列表数据
            this.setState({ hotList: getHotListForDetails(gid) });
        }
    }
    // 组件刷新
    componentDidUpdate() {
        // 获取游戏id
        const { gid } = this.props.match.params;
        // 判断id是否改变了
        if (gid !== this.curGameId) {
            // 判断是否存在
            if (!window.showInterAd) {
                // 引用AdJs
                initAdScript();
            }
            // 重新请求数据
            this.requestFromServer();
            // 展示插屏广告
            if (window.showInterAd) {
                window.showInterAd();
            }
            // 展示详情页锚广告
            if (window.showMDetailAd) {
                window.showMDetailAd();
            }
        }
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 判断是否存在
        if (!window.showInterAd) {
            // 引用AdJs
            initAdScript();
        }
        // 从服务器请求数据
        this.requestFromServer();
        // 展示插屏广告
        if (window.showInterAd) {
            window.showInterAd();
        }
        // 展示详情页锚广告
        if (window.showMDetailAd) {
            window.showMDetailAd();
        }
    }
    /**
     * 展示更多游戏列表
     * @param {*} _ty ：类型 100是全部，101是最热，102是最新，103是推荐
     */
    showMoreGames(_ty) {
        // 展示游戏列表
        let path = {
            pathname: '/home/gamelist/' + _ty,
        }
        // 跳转
        this.props.history.push(path);
    }
    /**
     * 打开游戏
     */
    playGame(_ver, _na) {
        // 打开游戏
        let path = {
            pathname: '/home/gameplay/' + _ver + "__aa__" + _na,
        }
        // 跳转
        this.props.history.push(path);
    }
    render() {
        // 获取数据
        const { isLoading, gameData, hotList } = this.state;
        // 横幅广告组件4
        var adLayer = null;
        // 判断是否存在横幅4
        if (window.slot4Obj) {
            // 横幅组件4
            adLayer = <div id={window.slot4Obj.id} style={{ minWidth: window.slot4Obj.size[0] + 'px', minHeight: window.slot4Obj.size[1] + 'px', marginLeft: '0px' }}></div>
        } else if (window.ads4Obj) {
            // 横幅组件4
            adLayer = <Adsbygoogle adObj={window.ads4Obj} />
        } else if (window.ya4Obj) {
            // 横幅组件4
            adLayer = <div id={window.ya4Obj.id} style={{ maxHeight: '300px' }}>
                <div style={{ height: '300px' }}></div>
            </div>
        }
        return (
            <div className="details-page">
                <div className='sticky'>
                    <NavBar
                        className='navBar'
                        onBack={() => {
                            window.history.back(-1);
                        }}
                        style={{
                            '--height': '50px',
                        }}
                    >{gameData ? gameData.na : ""}</NavBar>
                </div>
                {
                    gameData ?
                        <div className='gameLyr'>
                            <div className='iconLyr'>
                                <Image className='icon' src={getTrueUrl(2, gameData.ic1)} fit='fill' />
                            </div>
                            <div className="btnLyr" onClick={() => {
                                // 保存游戏数据
                                saveLastGameToLocal(gameData);
                                // 打开游戏
                                this.playGame(gameData.zn, gameData.na);
                            }}>
                                <div className='playTx'>Play Now</div>
                                <img className='playIc' src={IMAGE['playIcon']} alt="" />
                            </div>
                            <br />
                            {adLayer}
                            <div className='infoLyr'>
                                <div className='nameTx'>{gameData.na}</div>
                                <div className='starLyr'>
                                    <Rate
                                        allowHalf
                                        readOnly
                                        defaultValue={gameData.sn / 10}
                                        style={{
                                            '--active-color': '#f23e6c',
                                        }}
                                    />
                                </div>
                                {
                                    gameData.d && gameData.d.length > 0 ?
                                        <div className='descTx'>{gameData.d}</div>
                                        :
                                        null
                                }
                            </div>
                            {
                                hotList && hotList.length > 0 ?
                                    <div className='hotLyr'>
                                        <div className='titleLyr'>
                                            <div className='titleTx'>Hot games </div>
                                            <div className='moreTx' onClick={() => {
                                                // 展示更多游戏
                                                this.showMoreGames(GlobalCtg.listType.hots);
                                            }}>{"Show more >>"} </div>
                                        </div>
                                        <div className='rowLyr'>
                                            <GameIcon that={this} data={hotList[0]} />
                                            <GameIcon that={this} data={hotList[1]} />
                                        </div>
                                        <div className='rowLyr'>
                                            <GameIcon that={this} data={hotList[2]} />
                                            <GameIcon that={this} data={hotList[3]} />
                                        </div>
                                        <div className='rowLyr'>
                                            <GameIcon that={this} data={hotList[4]} />
                                            <GameIcon that={this} data={hotList[5]} />
                                        </div>
                                        <div className='rowLyr'>
                                            <GameIcon that={this} data={hotList[6]} />
                                            <GameIcon that={this} data={hotList[7]} />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <PageBottom />
                        </div>
                        :
                        null
                }
                <LoadingView visible={isLoading} />
            </div>
        )
    }
}

export default withRouter(IndexPage)