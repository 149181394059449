import React from 'react';
import { Swiper, Image, Rate, Divider } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import { LoadingView, GameIcon, Adsbygoogle, PageBottom, MyMenu } from '@component';
import { IMAGE } from "@/assets";
import { GlobalCtg } from "@config/config";
import { doQueryList } from '@util/HttpUtil';
import { cloneObj, randomSort } from '@util/GameUtil';
import { saveHotListToLocal, getLastGameList, getTrueUrl, initAdScript } from '@util/GameUtil';
import './styles.less';

/**
 * 游戏盒子列表
 */
function GamesBox(props) {
    // 上下文
    let that = props.that;
    // 获取游戏列表
    let list = props.games;
    // 列表类型
    let lType = props.lty;
    // 标题
    let title = 'All games';
    // 判断类型
    if (lType === GlobalCtg.listType.hots) {
        title = 'Hot games';
    } else if (lType === GlobalCtg.listType.news) {
        title = 'New games';
    } else if (lType === GlobalCtg.listType.last) {
        title = 'Played games';
    } else if (lType === GlobalCtg.listType.push) {
        title = 'Recommend';
    }
    // 返回界面
    return < >
        {
            list && list.length > 0 ?
                <div className='boxLyr'>
                    <div className='titleLyr'>
                        <div className='titleTx'>{title} </div>
                        <div className='moreTx' onClick={() => {
                            // 展示更多游戏
                            that.showMoreGames(lType);
                        }}>{"Show more >>"} </div>
                    </div>
                    <div className='rowLyr'>
                        <GameIcon that={that} data={list[0]} />
                        <GameIcon that={that} data={list[1]} />
                    </div>
                    {
                        list[2] ?
                            <div className='rowLyr'>
                                <GameIcon that={that} data={list[2]} />
                                <GameIcon that={that} data={list[3]} />
                            </div>
                            :
                            null
                    }
                    {
                        list[4] ?
                            <div className='rowLyr'>
                                <GameIcon that={that} data={list[4]} />
                                <GameIcon that={that} data={list[5]} />
                            </div>
                            :
                            null
                    }
                    {
                        list[6] ?
                            <div className='rowLyr'>
                                <GameIcon that={that} data={list[6]} />
                                <GameIcon that={that} data={list[7]} />
                            </div>
                            :
                            null
                    }
                    {
                        list[8] ?
                            <div className='rowLyr'>
                                <GameIcon that={that} data={list[8]} />
                                <GameIcon that={that} data={list[9]} />
                            </div>
                            :
                            null
                    }
                </div>
                :
                null
        }
    </>
}

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // 是否加载中
            isMenu: false, // 是否展示菜单
            pushList: null, // 推荐列表
            lastList: null, // 最近列表
            hotList: null, // 最热列表
            newList: null, // 最新列表
            allList: null, // 全部列表
            recList: null, // 推荐列表2
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {
        // 展示loading
        this.setState({ isLoading: true });
        // 1.获取推荐列表
        this.loadGameListByType(GlobalCtg.listType.push, () => {
            // 2.获取最热列表
            this.loadGameListByType(GlobalCtg.listType.hots, () => {
                // 3.获取最新列表
                this.loadGameListByType(GlobalCtg.listType.news, () => {
                    // 4.获取全部列表
                    this.loadGameListByType(GlobalCtg.listType.all, () => {
                        // 关闭loading
                        this.setState({ isLoading: false });
                    })
                })
            })
        })
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 判断是否存在
        if (!window.showMHomeAd) {
            // 引用AdJs
            initAdScript();
        }
        // 从服务器请求数据
        this.requestFromServer();
        // 展示首页锚广告
        if (window.showMHomeAd) {
            window.showMHomeAd();
        }
        // 展示插屏广告
        if (window.showInterAd) {
            window.showInterAd();
        }
        // 展示横幅1
        if (window.showNor1Ad) {
            window.showNor1Ad();
        }
        // 展示横幅2
        if (window.showNor2Ad) {
            window.showNor2Ad();
        }
        // // 展示横幅3
        // if (window.showNor3Ad) {
        //     window.showNor3Ad();
        // }
    }
    /**
     * 根据类型加载游戏列表
     * @param {*} _ty ：100是全部，101是最热，102是最新，103是推荐
     * @param {*} _cb ：回调
     */
    loadGameListByType(_ty, _cb) {
        // 执行查询
        doQueryList(_ty, 1, (_resultObj) => {
            // 判断是否挂载
            if (!this.mounted) {
                // 回调
                _cb && _cb();
                return
            }
            // 判断状态
            if (_resultObj.s === 0) {
                // 判断是否有数据
                if (_resultObj.data && _resultObj.data.list && _resultObj.data.list.length > 0) {
                    // 当前数据
                    let list = _resultObj.data.list;
                    // 判断类型
                    if (_ty === GlobalCtg.listType.all) { // 全部 
                        // 克隆数据
                        let cloneAllArr = cloneObj(list);
                        // 乱序数组
                        let randAllArr = [];
                        // 随机排序
                        randomSort(cloneAllArr, randAllArr);
                        // 刷新数据
                        this.setState({ allList: randAllArr.slice(0, 10) });
                    } else if (_ty === GlobalCtg.listType.hots) { // 最热 
                        // 保存数据到本地
                        saveHotListToLocal(list);
                        // 刷新数据
                        this.setState({ hotList: list.slice(0, 6) });
                    } else if (_ty === GlobalCtg.listType.news) { // 最新
                        // 刷新数据
                        this.setState({ newList: list.slice(0, 6) });
                    } else if (_ty === GlobalCtg.listType.push) { // 推荐 
                        // 克隆数据
                        let clonePushArr = cloneObj(list);
                        // 乱序数组
                        let randPushArr = [];
                        // 随机排序
                        randomSort(clonePushArr, randPushArr);
                        // 获取最近列表
                        var locallist = getLastGameList();
                        // 判断是否存在
                        if (!locallist) {
                            locallist = [];
                        }
                        // 刷新数据
                        this.setState({ pushList: list, recList: randPushArr.slice(0, 6), lastList: locallist.slice(0, 6) });
                    }
                }
            }
            // 回调
            _cb && _cb();
        });
    }
    /**
     * 展示更多游戏列表
     * @param {*} _ty ：类型 100是全部，101是最热，102是最新，103是推荐
     */
    showMoreGames(_ty) {
        // 展示游戏列表
        let path = {
            pathname: '/home/gamelist/' + _ty,
        }
        // 跳转
        this.props.history.push(path);
    }
    /**
     * 展示游戏详情
     * @param {*} _gid ：游戏id
     */
    showGameDetail(_gid) {
        // 展示游戏列表
        let path = {
            pathname: '/home/details/' + _gid,
        }
        // 跳转
        this.props.history.push(path);
    }
    /**
     * 关闭菜单
     */
    hideMenu() {
        // 隐藏菜单
        this.setState({ isMenu: false });
    }
    render() {
        // 获取数据
        const { isLoading, isMenu, pushList, recList, lastList, hotList, newList, allList } = this.state;
        // 横幅广告组件1
        var ad1Layer = null;
        // 判断是否存在横幅1
        if (window.slot1Obj) {
            // 横幅组件1
            ad1Layer = <div id={window.slot1Obj.id} style={{ minWidth: window.slot1Obj.size[0] + 'px', minHeight: window.slot1Obj.size[1] + 'px', marginLeft: '0px' }}></div>
        } else if (window.ads1Obj) {
            // 横幅组件1
            ad1Layer = <Adsbygoogle adObj={window.ads1Obj} />
        } else if (window.ya1Obj) {
            // 横幅组件1
            ad1Layer = <div id={window.ya1Obj.id} style={{ maxHeight: '300px' }}>
                <div style={{ height: '300px' }}></div>
            </div>
        }
        // 横幅广告组件2
        var ad2Layer = null;
        // 判断是否存在横幅2
        if (window.slot2Obj) {
            // 横幅组件2
            ad2Layer = <div id={window.slot2Obj.id} style={{ minWidth: window.slot2Obj.size[0] + 'px', minHeight: window.slot2Obj.size[1] + 'px', marginLeft: '0px' }}></div>
        } else if (window.ads2Obj) {
            // 横幅组件2
            ad2Layer = <Adsbygoogle adObj={window.ads2Obj} />
        } else if (window.ya2Obj) {
            // 横幅组件2
            ad2Layer = <div id={window.ya2Obj.id} style={{ maxHeight: '300px' }}>
                <div style={{ height: '300px' }}></div>
            </div>
        }
        // // 横幅广告组件3
        // var ad3Layer = null;
        // // 判断是否存在横幅3
        // if (window.slot3Obj) {
        //     // 横幅组件3
        //     ad3Layer = <div id={window.slot3Obj.id} style={{ minWidth: window.slot3Obj.size[0] + 'px', minHeight: window.slot3Obj.size[1] + 'px', marginLeft: '0px' }}></div>
        // } else if (window.ads3Obj) {
        //     // 横幅组件3
        //     ad3Layer = <Adsbygoogle adObj={window.ads3Obj} />
        // } else if (window.ya3Obj) {
        //     // 横幅组件3
        //     ad3Layer = <div id={window.ya3Obj.id} style={{ maxHeight: '300px' }}>
        //         <div style={{ height: '300px' }}></div>
        //     </div>
        // }

        // 使用window.location对象获取当前页面的URL
        var url = window.location.href;
        // 使用正则表达式解析出域名
        var curDomain = url.match(/:\/\/(.[^/]+)/)[1];
        // logo
        var logo = null;
        // 判断域名
        if (curDomain !== 'oceanygame.com') {
            logo = <img className='logo' src={IMAGE['hfboxLogo']} alt="" />
        }
        return (
            <div className="home-page">
                <div className='sticky'>
                    <div className='navBarLay' >
                        <div className='tmpLyr' >
                            <div className='logoLyr' >
                                {logo}
                            </div>
                            <div className='btnLyr' >
                                <img className='img' src={IMAGE['btnMenu']} alt="" onClick={() => {
                                    // 展示菜单
                                    this.setState({ isMenu: true });
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingView visible={isLoading} />
                {
                    pushList && pushList.length > 0 ?
                        <>
                            <Swiper
                                className='swiperLyr'
                                loop
                                autoplay
                                style={{
                                    '--track-padding': ' 0 0 16px',
                                }}
                            >
                                {
                                    pushList.map((_item, index) => (
                                        <Swiper.Item className='swiperItem' key={index} onClick={() => {
                                            // 展示游戏详情
                                            this.showGameDetail(_item.gid);
                                        }}>
                                            <div className='bannerLyr'>
                                                <Image src={getTrueUrl(1, _item.ic2)} fit='fill' />
                                                <div className='msgLyr' >
                                                    <div className='nameTx'>{_item.na}</div>
                                                    <div className='starLyr'>
                                                        <Rate
                                                            allowHalf
                                                            readOnly
                                                            defaultValue={_item.sn / 10}
                                                            style={{
                                                                '--star-size': '24px',
                                                                '--active-color': '#f23e6c',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Swiper.Item>
                                    ))
                                }
                            </Swiper>
                        </>
                        :
                        null
                }
                {ad1Layer}
                {
                    lastList && lastList.length > 0 ?
                        <>
                            <Divider className='divider' />
                            <GamesBox that={this} games={lastList} lty={GlobalCtg.listType.last} />
                        </>
                        :
                        null
                }
                <Divider className='divider' />
                <GamesBox that={this} games={hotList} lty={GlobalCtg.listType.hots} />
                {ad2Layer}
                <Divider className='divider' />
                <GamesBox that={this} games={newList} lty={GlobalCtg.listType.news} />
                {/* {ad3Layer} */}
                <Divider className='divider' />
                <GamesBox that={this} games={recList} lty={GlobalCtg.listType.push} />
                <Divider className='divider' />
                <GamesBox that={this} games={allList} lty={GlobalCtg.listType.all} />
                {
                    allList && allList.length > 0 ?
                        <PageBottom />
                        :
                        null
                }
                <MyMenu that={this} isVisible={isMenu} where={-1} />
            </div>
        )
    }
}

export default withRouter(IndexPage)